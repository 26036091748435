class SideBarBtns {
  Initialize() {
    window.addEventListener("scroll", this.ToggleButtons)
    document.getElementById("scroll-btn").addEventListener("click", this.ScrollToTop);
  }
  ToggleButtons() {
    if (document.body.scrollTop > 300 || document.documentElement.scrollTop > 300) {
      document.getElementById("scroll-btn").style.display = "block";
    } else {
      document.getElementById("scroll-btn").style.display = "none";
    }
  }
  ScrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    document.documentElement.scrollTo({ top: 0, behavior: 'smooth' });
  }
}

exports.onRouteUpdate = () => {
  let sidebarbtns = new SideBarBtns();
  if (document.getElementById("scroll-btn")) {
    sidebarbtns.Initialize();
  }
}